import {Button, ButtonProps, GridItem} from '@chakra-ui/react';
import React, {FC, ReactElement} from 'react';
import {Link} from 'react-router-dom';

import {ButtonType} from '@app/types/Common/ButtonType';

type ButtonAsLinkProps = {
  button: ButtonType;
};

const ButtonAsLink: FC<ButtonAsLinkProps> = ({button}): ReactElement => {
  const buttonStyles: ButtonProps = {
    width: '100%',
    height: '100%',
    fontSize: '2xl',
    border: '2px',
    textTransform: 'uppercase',
    whiteSpace: 'normal',
  };

  return (
    <GridItem as={Link} to={button.link} h="20">
      <Button leftIcon={button.icon} colorScheme={button.color} isDisabled={!button.enabled} {...buttonStyles}>
        {button.label}
      </Button>
    </GridItem>
  );
};

export default ButtonAsLink;
