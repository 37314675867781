import {Button, Card, Heading, List} from '@chakra-ui/react';
import React, {FC} from 'react';
import {FaFileZipper} from 'react-icons/fa6';
import {useOutletContext} from 'react-router-dom';

import {useLazyCustomizationFilesQuery} from '@app/store/camalo/orderApi.ts';
import {shopContext} from '@app/types/ContextType';
import {Order} from '@app/types/Order/Information/Order';

const OrderCustomization: FC<{order: Order}> = ({order}) => {
  const {shop} = useOutletContext<shopContext>();
  const [customizationFilesTrigger] = useLazyCustomizationFilesQuery();
  const downloadZip = async (): Promise<void> => {
    await customizationFilesTrigger({shopSlug: shop.slug!, order}).unwrap();
  };

  if (!order.carts.some(cart => cart.customizable)) {
    return <></>;
  }

  return (
    <Card mt={5} mx={2} p={3}>
      <Heading as="h4" mb="4">
        Perso
      </Heading>
      <List>
        <Button leftIcon={<FaFileZipper />} onClick={downloadZip}>
          Télécharger
        </Button>
      </List>
    </Card>
  );
};

export default OrderCustomization;
