import {Card, CardBody} from '@chakra-ui/react';
import {FC, ReactElement} from 'react';

import {StatBlock} from '@app/components/atoms/Statistic/StatBlock.tsx';
import {StatBlockType} from '@app/types/Statistic/Statistic.ts';

export type StatBlockProps = {
  blocks: StatBlockType[];
};

export const StatBlockList: FC<StatBlockProps> = ({blocks}): ReactElement => {
  return (
    <>
      {blocks.map((block, index) => {
        return (
          <Card key={index} variant="elevated">
            <CardBody>
              <StatBlock title={block.title} value={block.value} />
            </CardBody>
          </Card>
        );
      })}
    </>
  );
};
