import {Box, Grid} from '@chakra-ui/react';
import React, {FC} from 'react';

import ButtonAsLink from '@app/components/atoms/Button/ButtonAsLink';
import {ButtonType} from '@app/types/Common/ButtonType';
import {Role} from '@app/types/RoleEnum';

type ButtonActionListProps = {
  lines: ButtonType[][];
  roles: Role[];
};

const ButtonActionList: FC<ButtonActionListProps> = ({lines, roles}) => {
  return (
    <Box>
      {lines.map((line, index) => (
        <Grid key={index} templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} gap={6} pb={6}>
          {line.map((button, subIndex) => {
            if (roles.includes(button.role)) {
              return <ButtonAsLink key={subIndex} button={button} />;
            }
            return null;
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default ButtonActionList;
