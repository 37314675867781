import {Badge, Text} from '@chakra-ui/react';
import React, {FC, ReactElement, useContext} from 'react';
import {FaCircle, FaFolder, FaFolderOpen} from 'react-icons/fa6';
import {NavLink} from 'react-router-dom';

import NavItem from '@app/layouts/Sidebar/NavItem';
import {SidebarContext} from '@app/layouts/Sidebar/SideBarContext';
import {UserAccess} from '@app/types/UserAccess';

const SidebarItems: FC<{userAccess: UserAccess}> = ({userAccess}): ReactElement => {
  const {sidebarWidth} = useContext(SidebarContext);
  return (
    <>
      <NavLink key="warehouse" to={`/warehouse`}>
        {({isActive}) => (
          <NavItem icon={isActive ? FaFolderOpen : FaFolder} isActive={isActive} iconWidth="20px">
            Entrepôt
          </NavItem>
        )}
      </NavLink>
      <Text
        fontSize={sidebarWidth === 275 ? 'md' : 'xs'}
        fontWeight="bold"
        mx="auto"
        ps={{
          sm: '10px',
          xl: '16px',
        }}
        pt="18px"
        pb="12px"
        textTransform="uppercase"
      >
        Boutiques
      </Text>
      {userAccess.shops!.map(shop => {
        return (
          <NavLink key={shop.slug} to={`/shop/${shop.slug}`}>
            {({isActive}) => (
              <NavItem icon={FaCircle} isActive={isActive} iconWidth={isActive ? '12px' : '6px'}>
                {(shop.orderCount ?? 0) > 0 && (
                  <>
                    <Badge colorScheme="red" variant="solid" borderRadius="5px">
                      {shop.orderCount}
                    </Badge>{' '}
                    -{' '}
                  </>
                )}
                {shop.name}
              </NavItem>
            )}
          </NavLink>
        );
      })}
    </>
  );
};
export default SidebarItems;
