export default function downloadFile(data: any, fileName: string) {
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(data)
      : window.webkitURL.createObjectURL(data);
  const element = document.createElement('a');
  element.href = blobURL;
  element.download = fileName;
  // Safari need this
  if (typeof element.download === 'undefined') {
    element.setAttribute('target', '_blank');
  }
  document.body.appendChild(element);
  element.click();
  setTimeout(function () {
    document.body.removeChild(element);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
}
