import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {PrintElement} from '@app/types/PrintElement';
import {PrintLog} from '@app/types/PrintLog';

const initialState = {
  logs: [] as PrintLog[],
};

const printerLogSlice = createSlice({
  name: 'printerLog',
  initialState,
  reducers: {
    addNewLog: (state, action: PayloadAction<PrintLog>) => {
      state.logs.push(action.payload);
    },
    addNewLogs: (state, action: PayloadAction<PrintLog[]>) => {
      state.logs = [...state.logs, ...action.payload];
    },
    addNewLogFromPrinterElement: (state, action: PayloadAction<{printElement: PrintElement; pickingType: string}>) => {
      const printElement = action.payload.printElement;
      const type = action.payload.pickingType;
      const log: PrintLog = {
        date: new Date().toDateString(),
        name: printElement.title!,
        type,
        link: printElement.url,
      };
      state.logs.push(log);
    },
  },
});

export const {addNewLog, addNewLogs, addNewLogFromPrinterElement} = printerLogSlice.actions;

export default printerLogSlice.reducer;
