import {Button} from '@chakra-ui/react';
import {ThemeTypings} from '@chakra-ui/styled-system';
import React, {FC} from 'react';
import {FaSync} from 'react-icons/fa';

type ButtonActionProps = {
  name: string;
  color?: ThemeTypings['colorSchemes'];
  isDisabled?: boolean;
  isLoading?: boolean;
  displayBlock?: boolean;
  onClick: () => void;
};

export const ButtonAction: FC<ButtonActionProps> = ({
  name,
  color = 'red',
  isDisabled = false,
  isLoading = false,
  displayBlock = false,
  onClick,
}) => {
  return (
    <Button
      colorScheme={color}
      size="xl"
      disabled={isDisabled}
      onClick={onClick}
      sx={{textTransform: 'uppercase', minWidth: displayBlock ? '100%' : 'auto'}}
    >
      <span dangerouslySetInnerHTML={{__html: name}} />
      {isLoading && <FaSync className="ml-3 h-5 w-5 animate-spin" />}
    </Button>
  );
};

export const ButtonActionSkeleton: FC = () => {
  return (
    <Button isLoading size="xl" sx={{minWidth: '100%'}}>
      Loading...
    </Button>
  );
};
