import {Button, useToast} from '@chakra-ui/react';
import React, {FC, ReactElement, lazy, startTransition, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import Search from '@app/components/Shop/Shipment/Search/Search';
import {shopContext} from '@app/types/ContextType';

const ShipmentWrapperMono = lazy(async () => await import('./ShipmentWrapperMono'));
const ShipmentWrapperMulti = lazy(async () => await import('./ShipmentWrapperMulti'));

const ShipmentApp: FC = (): ReactElement => {
  const {printerList} = useOutletContext<shopContext>();
  const [shipmentName, setShipmentName] = useState('');
  const [canReset, setCanReset] = useState(false);
  const toast = useToast();

  const searchAction = (data: string): void => {
    startTransition(() => {
      setShipmentName(data);
      setCanReset(true);
    });
  };
  const resetAction = (): void => {
    setShipmentName('');
    setCanReset(false);
  };

  const onError = (message: string): void => {
    resetAction();
    toast({
      position: 'top-right',
      title: message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onFinish = (): void => {
    toast({
      position: 'top-right',
      title: "L'expédition est terminée",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    resetAction();
  };

  return (
    <>
      {shipmentName === '' && (
        <Search
          title={'Expédition - recherche'}
          placeholder={'Code barre du picking ou de la commande'}
          searchAction={searchAction}
        />
      )}
      {shipmentName.startsWith('MONO') && (
        <ShipmentWrapperMono printers={printerList} shipmentName={shipmentName} onError={onError} onFinish={onFinish} />
      )}
      {shipmentName !== '' && !shipmentName.startsWith('MONO') && (
        <ShipmentWrapperMulti
          printers={printerList}
          shipmentName={shipmentName}
          onError={onError}
          onFinish={onFinish}
        />
      )}
      {canReset && (
        <Button mt="4" onClick={resetAction}>
          Recommencer
        </Button>
      )}
    </>
  );
};

export default ShipmentApp;
