import {useSelector} from 'react-redux';

import {RootState, useAppDispatch} from '@app/store.ts';
import {addNewLog, addNewLogFromPrinterElement, addNewLogs} from '@app/store/printerLogSlice.ts';
import {PrintElement} from '@app/types/PrintElement.ts';
import {PrintLog} from '@app/types/PrintLog.ts';

export const usePrinterLog = () => {
  const dispatch = useAppDispatch();
  const logs = useSelector((state: RootState) => state.printerLog.logs);

  const addLog = (log: PrintLog) => {
    dispatch(addNewLog(log));
  };
  const addLogs = (log: PrintLog[]) => {
    dispatch(addNewLogs(log));
  };

  const addLogFromPrinterElement = (printElement: PrintElement, pickingType: string) => {
    dispatch(addNewLogFromPrinterElement({printElement, pickingType}));
  };

  return {logs, addLog, addLogs, addLogFromPrinterElement};
};
