import React, {FC, ReactElement} from 'react';

import {ButtonAction} from '@app/components/atoms/Button/ButtonAction';

type ButtonActionProps = {
  count: number;
  isLoading: boolean;
  isMono?: boolean;
  label?: string;
  onClick: () => void;
};

const PickingButton: FC<ButtonActionProps> = ({count, isLoading, onClick, isMono, label}): ReactElement => {
  const newLabel = isMono ? (label ?? `Mono - ${count}`) : (label ?? `Multi - ${count}`);

  return <ButtonAction name={newLabel} onClick={onClick} isDisabled={count === 0} isLoading={isLoading} />;
};

export default PickingButton;
